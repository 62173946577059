<template>
  <div>
    <!-- <div>
      <b-row align-h="start" class="mb-1">
        <b-form-checkbox size="sm">Small</b-form-checkbox>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          size="sm"
          class=""
          @click="hideShowFilter"
        >
          {{ filtersHidden == true ? "Show Filters" : "Hide Filters" }}
        </b-button>
      </b-row>
    </div> -->
    <b-alert variant="secondary" show>
      <b-row cols="12" class="mb-1 alert-heading">
        <b-col class="d-flex align-items-center justify-content-between">
          <b-form-checkbox
            v-if="$props.isPaymentPage != 'Y'"
            @change="hideShowFilter"
            checked="true"
            ><b>show filters</b></b-form-checkbox
          >

          <b-button
            v-if="$props.isPaymentPage != 'Y'"
            @click="onInputAdd"
            size="sm"
            variant="outline-primary"
          >
            show additional cost data
          </b-button>
        </b-col>
      </b-row>
    </b-alert>

    <b-card
      no-body
      v-if="this.filtersHidden == false && $props.isPaymentPage != 'Y'"
    >
      <div>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="12">
              <b-form @submit.prevent>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Title">
                      <v-select
                        v-model="filterTempMovie"
                        @search="GetAllMovie"
                        placeholder="Title"
                        label="full_title"
                        :options="optionTempMovie"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Licensee/Licensor">
                      <v-select
                        v-model="selectedCustomer"
                        placeholder="Licensee/Licensor"
                        label="company"
                        :options="optionCustomer"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Rights">
                      <v-select
                        :closeOnSelect="false"
                        :reduce="(label) => label.lbl_id"
                        v-model="subRights"
                        multiple
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="sub_rights_list"
                        label="lbl_name"
                        value="lbl_id"
                        placeholder="Select Sub Rights"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Revenue Type">
                      <v-select
                        v-model="selectedRight"
                        placeholder="Revenue Type"
                        label="title"
                        :options="optionRights"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Revenue Status">
                      <v-select
                        v-model="selectedStatus"
                        placeholder="Revenue Status"
                        label="title"
                        :options="optionStatus"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Revenue Date Range">
                      <flat-pickr
                        :disabled="
                          selectedStatus && selectedStatus.value == '3'
                            ? true
                            : false
                        "
                        v-model="revenueDateRange"
                        placeholder="Revenue Date"
                        class="form-control"
                        :config="{ mode: 'range', ...$flatPickrConfig }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Royalty Report Revenue">
                      <v-select
                        v-model="selectedRelevance"
                        placeholder="Royalty Report Revenue"
                        label="title"
                        :reduce="(r) => r.value"
                        :options="relevanceOptions"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col class="mt-1" md="6">
                    <b-button
                      size="sm"
                      :disabled="
                        selectedStatus && selectedStatus.value == '3'
                          ? true
                          : false
                      "
                      @click="onSearch()"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-info"
                      class="mr-1 mb-1"
                    >
                      Search
                    </b-button>
                    <b-button
                      size="sm"
                      @click="downloadCSV('normal')"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 mb-1"
                    >
                      Export Revenue CSV
                    </b-button>
                    <b-button
                      size="sm"
                      @click="downloadCSV('bookkeeping')"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 mb-1"
                    >
                      Export Bookkeeping Revenue CSV
                    </b-button>
                    <!-- <b-button
                      size="sm"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      @click="resetForm"
                      class="mb-1 mt-2"
                    >
                      Reset
                    </b-button> -->
                  </b-col>
                  <b-col md="2" lg="2" sm="12"> </b-col>
                </b-row>
                <!-- submit and reset -->
              </b-form>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <b-card no-body>
      <b-table
        bordered
        v-if="$can('read', 'revenue')"
        ref="selectableTable"
        :fields="fields"
        :items="revenueDataList"
        responsive
        empty-text="No  records found"
        show-empty
        small
        hover
      >
        <template #head(sr)="data">
          <b-badge v-if="totalRows" variant="success">{{ totalRows }}</b-badge>
        </template>
        <template #cell(sr)="data">
          {{ data.index + 1 + (currentPage - 1) * limit }}
        </template>

        <template v-slot:cell(selected)="data">
          <b-form-checkbox
            v-if="setIsInvoice == true"
            v-model="selectedRevenueItems"
            :value="data.item"
          >
          </b-form-checkbox>
        </template>

        <template #cell(date)="data">
          <div class="text-nowrap" v-if="data.item.status != '100'">
            <span>
              Revenue Dt. :

              {{
                data.item.date
                  ? moment(data.item.date).format("DD/MM/YYYY")
                  : ""
              }}<br
            /></span>
            <span v-if="data.item.right_type == '2'">
              Royalty Dt. :
              <b>{{
                data.item.revenue_royalty_date
                  ? moment(data.item.revenue_royalty_date).format("DD/MM/YYYY")
                  : ""
              }}</b></span
            >
          </div>
          <div class="text-nowrap" v-if="data.item.status == '100'">
            <span>
              Revenue Dt. :
              <flat-pickr
                v-if="data.item.status == '100'"
                :config="{
                  ...$flatPickrConfig,
                }"
                placeholder="Revenue Date"
                class="form-control"
                v-model="data.item.date" />

              <span v-else>{{
                data.value ? moment(data.value).format("DD/MM/YYYY") : ""
              }}</span>
              <br
            /></span>
            <span>
              Royalty Dt. :
              <flat-pickr
                v-if="data.item.status == '100'"
                :config="{
                  ...$flatPickrConfig,
                }"
                placeholder="Royalty Date"
                class="form-control"
                v-model="data.item.revenue_royalty_date"
              />

              <span v-else>{{
                data.value ? moment(data.value).format("DD/MM/YYYY") : ""
              }}</span>
            </span>
          </div>
        </template>

        <template #cell(updatedAt)="data">
          {{ data.value ? moment(data.value).format("DD/MM/YYYY HH:mm") : "" }}
          <b-badge
            v-if="
              $can('create', 'expense') &&
              data.item.status != '100' &&
              $props.isPaymentPage != 'Y'
            "
            type="submit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="danger"
            @click="showDeleteConfirm(data.item, 'delete')"
          >
            Delete
          </b-badge>
          <!-- <br /> -->
        </template>

        <template #cell(addmission)="data">
          {{
            data.item.right_type == "1" && data.item.addmission
              ? data.item.addmission
              : 0
          }}
        </template>
        <template #cell(unit_sold)="data">
          {{
            data.item.right_type == "2" && data.item.unit_sold
              ? data.item.unit_sold
              : 0
          }}
        </template>
        <template #cell(share_net)="data">
          <div
            class="text-nowrap"
            v-if="data.item.status != '100' || data.item.inv_number"
          >
            <b>
              {{
                data.item.right_type == "1"
                  ? "NA"
                  : formatCurrency(data.item.share_net)
              }}</b
            >
          </div>
          <div
            class="text-nowrap"
            v-if="data.item.status == '100' && !data.item.inv_number"
          >
            <b-form-input
              v-model="data.item.boNet"
              placeholder="BO Net"
              type="number"
              v-if="data.item.right_type == '1'"
            ></b-form-input>
            <b-form-input
              v-model="data.item.share_net"
              placeholder="Share Net"
              type="number"
              v-else
            >
            </b-form-input>
          </div>
        </template>
        <template #cell(rentalNet)="data">
          <b>{{
            data.item.right_type == "1"
              ? formatCurrency(data.item.rentalNet)
              : "NA"
          }}</b>
        </template>
        <template #cell(boNet)="data">
          <b>
            {{
              data.item.right_type == "1"
                ? formatCurrency(data.item.boNet)
                : "NA"
            }}</b
          >
        </template>

        <template #cell(title)="data">
          {{ data.item.uniqueId }}-
          {{ data.item.title }}
        </template>

        <template #cell(company)="data">
          <div class="text-nowrap">
            <span
              ><p>Lisensee : {{ data.item.company }}</p>
              <p>Licensor : {{ data.item.licensor_company }}</p></span
            >
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <span
              >Revenue Type:
              {{ data.item.right_type == "1" ? "Theatrical" : "Other" }}</span
            >
            <div v-if="data.item.is_billed == '1'">
              <b-badge v-if="data.item.inv_number" variant="light-primary">
                billed with #inv {{ data.item.inv_number }}</b-badge
              >
              <b-badge v-else variant="light-primary"> Direct Billing</b-badge>
            </div>

            <div v-else><span>NA</span></div>
            <div v-if="$props.isPaymentPage == 'Y'" class="mt-1">
              <p>Payment Date</p>
              <flat-pickr
                v-if="$props.isPaymentPage == 'Y'"
                :config="{
                  ...$flatPickrConfig,
                }"
                v-model="data.item.revenue_payment_date"
                placeholder="Payment Date"
                class="form-control"
              />
              <b-button
                @click="showDeleteConfirm(data.item, 'update')"
                class="mt-1"
                size="sm"
                variant="primary"
                >Update</b-button
              >
            </div>
          </div>
        </template>
      </b-table>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                @change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                :limit="15"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BProgress,
  BBadge,
  BButton,
  BTooltip,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BAlert,
  BTabs,
  BTab,
  VBTooltip,
  BPagination,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import {
  GetVendorRevenueData,
  DeleteRevenueById,
} from "@/apiServices/RevenueServies";
import vSelect from "vue-select";
import moment from "moment";
import { getAllData } from "@/apiServices/MastersServices";
import { getAllVendor } from "@/apiServices/VendorServices";
import { getAllMovie } from "@/apiServices/MovieServices";
import Ripple from "vue-ripple-directive";
import { TokenService } from "@/apiServices/storageService";

import flatPickr from "vue-flatpickr-component";
import { downloadFromURL } from "@/utils/helpers";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { formatCurrency } from "@/utils/helpers";
import { debounce } from "@/utils/helpers";

export default {
  props: {
    isPaymentPage: {
      type: String,
    },
    invNumber: {
      type: Number,
    },
  },

  components: {
    BCard,
    BTable,
    BProgress,
    BBadge,
    vSelect,
    BButton,
    BTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BAlert,
    BBadge,
    flatPickr,
    BTabs,
    BTab,
    BFormRadio,
    VBTooltip,
    BPagination,
    ToastificationContentVue,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      stickyHeader: true,
      selectAllStatus: "0",
      filterTempTerritory: null,
      optionTempTerritory: [],
      sub_rights_list: [],
      subRights: [],
      filterTempRights: null,
      optionTempRights: [],
      filterTempMovie: null,
      optionTempMovie: [],
      revenueDateRange: null,
      selectedCustomer: null,
      selectedRight: null,
      optionCustomer: [],
      relevanceOptions: [
        { title: "All", value: null },
        { title: "Relevant For Royalty Report", value: 1 },
        { title: "Not Relevant For Royalty Report", value: 0 },
      ],
      optionRights: [
        { title: "Theatrical Rights", value: 1 },
        { title: "Other Rights", value: 2 },
      ],

      selectedStatus: null,
      selectedRelevance: 1,
      setIsInvoice: false,
      optionStatus: [
        { title: "Billed", value: 1 },
        { title: "Unbilled", value: 0 },
        // { title: "Generate Manual Invoice", value: 3 },
      ],
      moment: moment,
      currentPage: 1,
      totalPages: 0,
      limit: 25,
      totalRows: null,
      perPageOptions: [25, 50, 100],
      fields: [
        { key: "sr", label: "SR" },
        { key: "actions", label: "Actions" },
        //
        // { key: "selected", label: "" },
        // { key: "uniqueId", label: "Unique Id" },
        { key: "title", label: "Movie" },
        { key: "company", label: "Customer" },

        { key: "rightIdentifier", label: "Rights" },
        { key: "mainTerritory", label: "Territory" },
        { key: "addmission", label: "Admissions" },
        { key: "unit_sold", label: "Unit Sold" },

        {
          key: "share_net",
          label: "Share Net (CHF)",
          class: "text-right",
          variant: "danger",
        },
        {
          key: "boNet",
          label: "Bo Net (CHF)",
          variant: "danger",
          class: "text-right",
        },
        {
          key: "rentalNet",
          label: "Rental Net (CHF)",
          variant: "danger",
          class: "text-right",
        },
        { key: "date", label: "Dates (DD/MM/YYYY)" },
        // { key: "revenue_royalty_date", label: "DATE FOR ROYALTY REPORT" },

        // { key: "unit_sold", label: "Unit Sold / Addmission" },

        // { key: "rentalNet", label: "RentalNet" },

        { key: "updatedAt", label: "Last Updated" },
      ],

      revenueDataList: [],
      revenueDataListAll: [],
      selectedRevenueItems: [],
      showGenerateInvoice: false,
      filtersHidden: false,
      selectedPage: "revenue",
      optionsPage: [
        { text: "Add Revenue List", value: "revenue" },
        { text: "Additional Cost List", value: "additional" },
      ],
    };
  },

  watch: {
    selectedRevenueItems() {
      if (this.selectedRevenueItems.length > 0) {
        if (this.selectedRevenueItems.length != this.revenueDataList.length) {
          this.selectAllStatus = "0";
        } else {
          this.selectAllStatus = "1";
        }
      } else {
      }
    },
  },
  async beforeMount() {
    this.getAllRevenueData();
    this.GetAllVendor();
    this.GetAllMovie();
    const response1 = await getAllData({
      master: "sub_right",
      deleted: "0",
    });
    if (response1.status == 200) {
      console.log(response1.data.data.rows, "hh");
      this.sub_rights_list = response1.data.data.rows.filter((z) => {
        return z.lbl_value1 != "" && z.lbl_value1 != null;
      });
    }
  },
  methods: {
    onUpdateRevenuePaymentDate() {},
    onInputAdd() {
      this.$router.push("/additional-cost");
    },
    formatCurrency,

    downloadCSV(type) {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }
        let payload = {
          invoice_number:
            this.$props && this.$props.invNumber ? this.$props.invNumber : null,
          vendor_id: this.selectedCustomer
            ? this.selectedCustomer.vendor_id
            : "",
          rightType: this.selectedRight ? this.selectedRight.value : "",
          billedStatus: this.selectedStatus ? this.selectedStatus.value : "",

          start_date: this.revenueDateRange
            ? this.revenueDateRange.split("to")[0]
            : "",
          end_date: this.revenueDateRange
            ? this.revenueDateRange.split("to")[1]
            : "",
          search: this.search,
          limit: this.limit,
          page: this.currentPage,
          movie_id:
            this.filterTempMovie && this.filterTempMovie.id
              ? this.filterTempMovie.id
              : null,
          subRights: this.subRights,
          relevant_for_royalty: this.selectedRelevance,
          reporttype: type,
        };

        let baseURL = `${process.env.VUE_APP_BASEURL}/revenue/sheet/download-vendor-revenue?authorization=${token}`;

        const urlStr = this.getURLString(baseURL, payload);

        console.log({ urlStr });

        downloadFromURL(urlStr, "Revenue", "csv");
      } catch (error) {
        console.error(`Error in downloadCSV `, error);
      }
    },
    getURLString(baseURL, payload) {
      let urlKeyValuePairs = [];

      for (let key in payload) {
        urlKeyValuePairs.push(`${key}=${payload[key]}`);
      }

      return baseURL + "&" + urlKeyValuePairs.join("&");
    },
    async getAllRevenueData() {
      this.selectedRevenueItems = [];
      this.revenueDataList = [];
      this.revenueDataListAll = [];
      this.optionTempTerritory = [];
      this.optionTempRights = [];

      try {
        let payload = {
          invoice_number:
            this.$props && this.$props.invNumber ? this.$props.invNumber : null,
          vendor_id: this.selectedCustomer
            ? this.selectedCustomer.vendor_id
            : "",
          rightType: this.selectedRight ? this.selectedRight.value : "",
          billedStatus: this.selectedStatus ? this.selectedStatus.value : "",

          start_date: this.revenueDateRange
            ? this.revenueDateRange.split("to")[0]
            : "",
          end_date: this.revenueDateRange
            ? this.revenueDateRange.split("to")[1]
            : "",
          search: this.search,
          limit: this.limit,
          page: this.currentPage,
          movie_id:
            this.filterTempMovie && this.filterTempMovie.id
              ? this.filterTempMovie.id
              : null,
          subRights: this.subRights,
          relevant_for_royalty: this.selectedRelevance,
        };
        const response = await GetVendorRevenueData(payload);
        if (response.data.status) {
          this.revenueDataListAll = response.data.data.data;
          this.revenueDataList = response.data.data.data;
          if (
            response.data.data.pagination &&
            response.data.data.pagination.total
          ) {
            this.totalRows = response.data.data.pagination.total;
          }
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },
    async GetAllVendor() {
      try {
        const response = await getAllVendor({});
        if (response.data) {
          this.optionCustomer = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
    },

    async GetAllMovie(searchTerm) {
      // this.optionTempMovie = [];
      //this.debouncdFetchMovies is used to avoid multiple api fetching
      // if(!this._debouncedFetchMovies){
      //   this._debouncedFetchMovies = debounce (async(searchTerm) =>{
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionTempMovie = response.data.data.data;
          console.log(this.optionTempMovie, "dad");
        }
      } catch (err) {
        console.log("Error in getting movies list ", err);
      }
      //   });
      // }
      // this._debouncedFetchMovies(searchTerm);
    },

    async deleteRevenueById(data, id, type) {
      const response = await DeleteRevenueById({
        revenue_data_id: id,
        type: type,
        data: data,
      });
      if (response.data.status) {
        this.getAllRevenueData();
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Deleted Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
      return this.$toast({
        component: ToastificationContentVue,
        props: {
          title: response.data.message || "Delete Failed",
          icon: "EditIcon",
          variant: "danger",
        },
      });
    },
    showDeleteConfirm(data, type) {
      this.$bvModal
        .msgBoxConfirm(
          type == "delete"
            ? "Are you sure, you want to delete?"
            : "Are you sure, you want to Update Payment Dates?",
          {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value === true)
            this.deleteRevenueById(data, data.revenue_data_id, type);
        })
        .catch((err) => {
          // An error occurred
        });
    },
    hideShowFilter() {
      this.filtersHidden = !this.filtersHidden;
    },
    onPageChange() {
      this.$nextTick(() => {
        this.getAllRevenueData();
      });
    },
    async onSearch() {
      this.setIsInvoice = false;
      this.getAllRevenueData();
    },
    async resetForm() {
      this.selectedCustomer = null;
      this.selectedRight = null;
      this.selectedStatus = null;
      this.revenueDateRange = null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
